import { useMutation } from "react-query";
const API_URL = process.env.REACT_APP_API_URL as string;

const [protocol, host] = API_URL.split("//");

const BASE_URL = `${protocol === "https:" ? "wss" : "ws"}://${host}`;

const useUploadMutation = () => {
  return useMutation((file: File) => {
    return new Promise((resolve, reject) => {
      const socket = new WebSocket(`${BASE_URL}/upload`, [
        window.localStorage.getItem("Authorization") as string,
      ]);

      socket.onopen = function () {
        const reader = new FileReader();

        reader.onload = function (e) {
          if (!e.target?.result) {
            return;
          }

          socket.send(
            JSON.stringify({
              filename: file.name,
              ext: file.name.split(".").pop(),
              file: e.target.result,
            })
          );
        };

        reader.readAsDataURL(file);
      };

      socket.onmessage = function ({ data }) {
        socket.close();

        const { file, error, message } = JSON.parse(
          data
        ) as ISocketUploadResponse;

        if (error) {
          return reject(new Error(message));
        }

        return resolve(file);
      };

      socket.onerror = function (error) {
        socket.close();

        return reject(error);
      };
    });
  });
};

export default useUploadMutation;
