import {
  Layout,
  Form,
  Button,
  Input,
  Select,
  message,
  Skeleton,
  Row,
  Col,
} from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import map from "lodash/map";

import PageHeader from "../../components/PageHeader";

import { FormProvider } from "../../context/Form";
import { useMutation, useQuery } from "@apollo/client";

import {
  QUERY_DOCTOR,
  QUERY_SPECIALTIES,
  QUERY_DEPARTMENTS,
  QUERY_DOCTORS,
} from "../../config/graphql/queries";
import {
  CREATE_DOCTOR,
  UPDATE_DOCTOR,
  CREATE_DEPARTMENT,
} from "../../config/graphql/mutations";
import { useCallback, useMemo, useState } from "react";

type UserFormInput = {
  email: string;
  phone: string;
  mobile: string;
  role: string;
  confirm: string;
  password: string;
  displayName?: string;
};

const SpecialtySelect = (props: {
  id?: string;
  value?: string[];
  onChange?: (value: any) => void;
}) => {
  const [search, setSearch] = useState("");

  const onSearch = useCallback((value) => {
    setSearch(value);
  }, []);

  const { data, loading } =
    useQuery<{ specialties: ISpecialty[] }>(QUERY_SPECIALTIES);

  const options = useMemo(
    () =>
      map(data?.specialties, (item) => ({ label: item.title, value: item.id })),
    [data]
  );

  return (
    <Select
      loading={loading}
      disabled={loading}
      searchValue={search}
      options={options}
      onSearch={onSearch}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const DepartmentSelect = (props: {
  id?: string;
  value?: string[];
  onChange?: (value: any) => void;
  limit?: number;
}) => {
  const { value = [], onChange = () => null, limit = 2 } = props;

  const { data, loading } =
    useQuery<{ departments: IDepartment[] }>(QUERY_DEPARTMENTS);

  const options = useMemo(
    () =>
      map(data?.departments, (item) => ({ label: item.title, value: item.id })),
    [data]
  );

  const isMaxValues = value.length === limit;

  return (
    <Select
      loading={loading}
      disabled={loading}
      mode="multiple"
      options={options}
      value={props.value}
      onChange={(value) => {
        onChange(value.slice(0, limit));
      }}
      {...(isMaxValues && { open: false })}
    />
  );
};

const DoctorRoute = () => {
  const { t } = useTranslation(["user"]);

  const history = useHistory();
  const { pathname } = useLocation();

  const [form] = Form.useForm<{
    title?: string;
    specialty?: string;
    departments?: string[];
  }>();

  const { doctorId } = useParams<{ doctorId?: string }>();

  const { loading: isLoading } = useQuery<{ doctor: IPropertyDoctor }>(
    QUERY_DOCTOR,
    {
      variables: {
        id: doctorId,
      },
      skip: !doctorId,
      onCompleted: ({ doctor }) => {
        form.setFieldsValue({
          ...doctor,
          departments: map(doctor?.departments, "id"),
          specialty: doctor?.specialty?.id,
        });
      },
      onError: (error) => {
        message.error(error.message);

        history.replace("/doctors");
      },
    }
  );

  const [onCreateDoctor, { loading: isCreating }] = useMutation<{
    addDoctor: IPropertyDoctor;
  }>(CREATE_DOCTOR, {
    refetchQueries: [{ query: QUERY_DOCTORS }],
    onCompleted: ({ addDoctor: data }) => {
      message.success("Doctor created");

      history.replace(`/doctors/${data?.id}`);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const [onUpdateDoctor, { loading: isUpdating }] = useMutation(UPDATE_DOCTOR, {
    refetchQueries: [{ query: QUERY_DOCTORS }],
    onCompleted: () => {
      message.success("Doctor updated");
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const onSubmit = (input: UserFormInput) => {
    if (doctorId) {
      return onUpdateDoctor({
        variables: { input: { ...input, id: doctorId } },
      });
    }

    return onCreateDoctor({ variables: { input } });
  };

  return (
    <>
      <PageHeader
        title="Doctors"
        breadcrumb={{
          routes: [
            {
              path: "/doctors",
              breadcrumbName: "Doctors",
            },
            {
              breadcrumbName: "Doctor",
              path: pathname,
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content">
        <FormProvider
          form={form}
          layout="vertical"
          initialValues={{
            title: "",
            departments: [],
            specialty: "",
          }}
          requiredMark="optional"
          onFinish={onSubmit}
        >
          <Skeleton loading={isLoading}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} xxl={8}>
                <Form.Item
                  label="Display name"
                  name="title"
                  rules={[{ required: true, type: "string" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Departments" name="departments">
                  <DepartmentSelect />
                </Form.Item>
                <Form.Item label="Specialties" name="specialty">
                  <SpecialtySelect />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isCreating || isUpdating}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Skeleton>
        </FormProvider>
      </Layout.Content>
    </>
  );
};

export default DoctorRoute;
