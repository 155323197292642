import { Switch, Route, useRouteMatch } from "react-router-dom";

import Screen from "./Screen";
import Screens from "./Screens";

const ScreensRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Screens />
      </Route>
      <Route path={`${path}/:screenId`}>
        <Screen />
      </Route>
    </Switch>
  );
};

export default ScreensRouter;
