import { gql } from "@apollo/client";

export const CURRENT_USER_QUERY = gql`
  query user {
    me {
      id
      firstName
      lastName
    }
  }
`;
