import React, { useMemo } from "react";

import {
  ApolloClient,
  ApolloProvider as Provider,
  from as createLink,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { cache } from "./cache";

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("Authorization");

  return {
    headers: {
      ...headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };
});

export const ApolloProvider = React.memo(({ children }) => {
  const client = useMemo(() => {
    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            switch (error?.extensions?.code) {
              case "UNAUTHENTICATED": {
                window.localStorage.removeItem("Authorization");

                return forward(operation);
              }

              default: {
                break;
              }
            }
          }
        }

        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          // if you would also like to retry automatically on
          // network errors, we recommend that you use
          // @apollo/client/link/retry
        }
      }
    );

    const httpLink = new HttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    });

    const link = createLink([authLink, errorLink, httpLink]);

    return new ApolloClient({
      link,
      cache,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Provider client={client}>{children}</Provider>;
});
