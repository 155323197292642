import React, { useCallback } from "react";
import { useQuery } from "@apollo/client";
import { Route, Redirect, useLocation, RouteProps } from "react-router-dom";

import { CURRENT_USER_QUERY } from "../lib/graphql/query";

export const RoutePublic = React.memo(
  ({ children, redirect, ...rest }: RouteProps & { redirect?: string }) => {
    const location = useLocation<{ from?: { pathname?: string } }>();

    const { loading, data } = useQuery<{ me: IUser }>(CURRENT_USER_QUERY, {
      fetchPolicy: "cache-and-network",
    });

    const pathname = redirect || location?.state?.from?.pathname;

    const render = useCallback(() => {
      if (loading) {
        return null;
      }

      if (data?.me) {
        return (
          <Redirect
            to={{
              pathname,
            }}
          />
        );
      }

      return children;
    }, [children, data?.me, loading, pathname]);

    return <Route {...rest} render={render} />;
  }
);

export const RoutePrivate = React.memo(
  ({
    children,
    redirect = "/login",
    ...rest
  }: RouteProps & { redirect?: string }) => {
    const location = useLocation();

    const { loading, data } = useQuery<{ me: IUser }>(CURRENT_USER_QUERY, {
      fetchPolicy: "cache-and-network",
    });

    const render = useCallback(() => {
      if (loading) {
        return null;
      }

      if (!!data?.me) {
        return children;
      }

      return (
        <Redirect
          to={{
            pathname: redirect,
            state: { from: location },
          }}
        />
      );
    }, [children, data?.me, loading, location, redirect]);

    return <Route {...rest} render={render} />;
  }
);
