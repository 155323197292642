import { gql } from "@apollo/client";
import { FRAGMENT_PROPERTY_DOCTOR, FRAGMENT_VISIONECT_ITEM } from "./fragments";

export const CREATE_DOCTOR = gql`
  mutation addDoctor($input: AddDoctorInput!) {
    addDoctor(input: $input) {
      ...FragmentPropertyDoctor
    }
  }
  ${FRAGMENT_PROPERTY_DOCTOR}
`;

export const UPDATE_DOCTOR = gql`
  mutation updateDoctor($input: UpdateDoctorInput!) {
    updateDoctor(input: $input) {
      ...FragmentPropertyDoctor
    }
  }
  ${FRAGMENT_PROPERTY_DOCTOR}
`;

export const DELETE_DOCTOR = gql`
  mutation deleteDoctor($id: ID!) {
    deleteDoctor(id: $id) {
      ...FragmentPropertyDoctor
    }
  }
  ${FRAGMENT_PROPERTY_DOCTOR}
`;

export const CREATE_DEPARTMENT = gql`
  mutation addDepartment($input: AddDepartmentInput!) {
    addDepartment(input: $input) {
      id
      title
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation updateDepartment($input: UpdateDepartmentInput!) {
    updateDepartment(input: $input) {
      id
      title
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation deleteDepartment($id: ID!) {
    deleteDepartment(id: $id) {
      id
      title
    }
  }
`;

export const CREATE_SPECIALTY = gql`
  mutation addSpecialty($input: AddSpecialtyInput!) {
    addSpecialty(input: $input) {
      id
      title
    }
  }
`;

export const UPDATE_SPECIALTY = gql`
  mutation updateSpecialty($input: UpdateSpecialtyInput!) {
    updateSpecialty(input: $input) {
      id
      title
    }
  }
`;

export const DELETE_SPECIALTY = gql`
  mutation deleteSpecialty($id: ID!) {
    deleteSpecialty(id: $id) {
      id
      title
    }
  }
`;

export const UPDATE_VISIONECT_ITEM = gql`
  query updateVisionectItem($id: ID!) {
    updateVisionectItem(id: $id) {
      ...FragmentVisionectItem
    }
  }
  ${FRAGMENT_VISIONECT_ITEM}
`;

export const UPDATE_VISIONECT = gql `
  mutation updateVisionect($visionectInput: UpdateVisionect!, $propertiesInput: AddPropertiesInput!) {
    updateVisionect(input: $visionectInput) {
      id
      title
    }
    addProperties(input: $propertiesInput) {
      id
      properties {
        id
        ... on Doctor {
          title
        }
      }
    }
  }
`;