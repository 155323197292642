import React from "react";

import { Button, Layout, Space, Table, Tag, Popconfirm, message } from "antd";

import map from "lodash/map";

import { useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import type { ColumnsType } from "antd/lib/table";

import PageHeader from "../../components/PageHeader";
import ErrorLayout from "../../components/ErrorLayout";

import { QUERY_DOCTORS } from "../../config/graphql/queries";
import { DELETE_DOCTOR } from "../../config/graphql/mutations";

const ActionDelete = ({ data: { id } }: { data: IPropertyDoctor }) => {
  const [onDelete, { loading }] = useMutation(DELETE_DOCTOR, {
    variables: { id },
    onCompleted: () => {
      message.success("Doctor deleted");
    },
    onError: (error: Error) => message.error(error.message),
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY_DOCTORS }],
  });

  return (
    <Popconfirm
      placement="topLeft"
      title="Are you sure you want to delete this doctor?"
      onConfirm={() => onDelete()}
      okText="Delete"
      okType="danger"
      cancelText="Cancel"
      disabled={loading}
    >
      <Button type="text" danger disabled={loading}>
        Delete
      </Button>
    </Popconfirm>
  );
};

const useTableColumns = (): ColumnsType<IPropertyDoctor> => {
  return [
    {
      dataIndex: "title",
      title: "Name",
      ellipsis: true,
      render: (value: string, data: IPropertyDoctor) => (
        <Link to={`/doctors/${data.id}`}>{value}</Link>
      ),
    },
    {
      dataIndex: "departments",
      title: "Departments",
      ellipsis: true,
      render: (value: IDepartment[], data: IPropertyDoctor) =>
        map(value, (item) => (
          <a href={`/departments/${item.id}`} target="_blank" rel="noreferrer">
            <Tag key={item.id}>{item.title}</Tag>
          </a>
        )),
    },
    {
      dataIndex: ["specialty", "title"],
      title: "Specialty",
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_value: any, data: IPropertyDoctor) => (
        <Space size="middle">
          <Link to={`/doctors/${data.id}`}>
            <Button type="primary">View</Button>
          </Link>
          <ActionDelete data={data} />
        </Space>
      ),
    },
  ];
};

export const DoctorsTable = () => {
  const columns = useTableColumns();

  const { loading, data, error } =
    useQuery<{ doctors: IPropertyDoctor[] }>(QUERY_DOCTORS);

  return (
    <ErrorLayout error={error}>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data?.doctors ?? []}
        loading={loading}
        scroll={{ x: true }}
      />
    </ErrorLayout>
  );
};

const DoctorsRoute = React.memo(() => {
  return (
    <>
      <PageHeader
        title="Doctors"
        breadcrumb={{
          routes: [
            {
              path: "/doctors",
              breadcrumbName: "Doctors",
            },
          ],
        }}
        extra={[
          <Link key="create" to="/doctors/new">
            <Button type="primary">Create</Button>
          </Link>,
        ]}
      />

      <Layout.Content className="site-layout-content">
        <DoctorsTable />
      </Layout.Content>
    </>
  );
});

export default DoctorsRoute;
