import { gql } from "@apollo/client";
import { FRAGMENT_PROPERTY_DOCTOR, FRAGMENT_VISIONECT } from "./fragments";

export const QUERY_DOCTORS = gql`
  query doctors(
    $filter: DoctorFilterType
    $sort: DoctorSortType
    $pagination: PaginationType
  ) {
    doctors(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentPropertyDoctor
    }
    # doctorsCount(filter: $filter, sort: $sort, pagination: $pagination)
  }
  ${FRAGMENT_PROPERTY_DOCTOR}
`;

export const QUERY_DOCTOR = gql`
  query doctor($id: ID!) {
    doctor(id: $id) {
      ...FragmentPropertyDoctor
    }
  }
  ${FRAGMENT_PROPERTY_DOCTOR}
`;

export const QUERY_DEPARTMENTS = gql`
  query departments(
    $filter: DepartmentFilterType
    $sort: DepartmentSortType
    $pagination: PaginationType
  ) {
    departments(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      title
    }
  }
`;

export const QUERY_DEPARTMENT = gql`
  query department($id: ID!) {
    department(id: $id) {
      id
      title
      logo {
        id
        path
      }
    }
  }
`;

export const QUERY_SPECIALTIES = gql`
  query specialties(
    $filter: SpecialtyFilterType
    $sort: SpecialtySortType
    $pagination: PaginationType
  ) {
    specialties(filter: $filter, sort: $sort, pagination: $pagination) {
      id
      title
    }
  }
`;

export const QUERY_SPECIALTY = gql`
  query specialty($id: ID!) {
    specialty(id: $id) {
      id
      title
    }
  }
`;

export const QUERY_VISIONECTS = gql`
  query visionects(
    $filter: VisionectFilterType
    $sort: VisionectSortType
    $pagination: PaginationType
  ) {
    visionects(filter: $filter, sort: $sort, pagination: $pagination) {
      ...FragmentVisionect
      previewUrl
    }
  }
  ${FRAGMENT_VISIONECT}
`;

export const QUERY_VISIONECT = gql`
  query visionect($id: ID!) {
    visionect(id: $id) {
      ...FragmentVisionect
    }
  }
  ${FRAGMENT_VISIONECT}
`;
