import React, { useState } from "react";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Input, Button, Row, Col, Layout, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

import { CURRENT_USER_QUERY } from "../../lib/graphql/query";

const { Content } = Layout;

const QUERY_LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      expiresIn
    }
  }
`;

const Login = React.memo(() => {
  const [login, { loading }] = useMutation(QUERY_LOGIN, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
    awaitRefetchQueries: false,
    update: (
      _cache,
      {
        data: {
          login: { token },
        },
      }
    ) => {
      window.localStorage.setItem("Authorization", token);
    },
  });

  const onSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    return login({ variables: { input: { email, password } } }).catch((error) =>
      message.error(
        error?.networkError?.result?.errors?.[0]?.message ?? error?.message
      )
    );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="d-flex align-items-center">
        <Row className="w-100" justify="center" align="middle">
          <Col xs={20} sm={14} md={10} xl={6} xxl={4}>
            <Form
              layout="vertical"
              initialValues={{
                email: "",
                password: "",
              }}
              requiredMark="optional"
              onFinish={onSubmit}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  type="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className="w-100"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
});

export default Login;
