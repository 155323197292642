import {
  Layout,
  Form,
  Button,
  Input,
  message,
  Skeleton,
  Row,
  Col,
  Upload,
  Image,
} from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import PageHeader from "../../components/PageHeader";

import { FormProvider } from "../../context/Form";
import { useMutation, useQuery } from "@apollo/client";

import {
  QUERY_DEPARTMENT,
  QUERY_DEPARTMENTS,
} from "../../config/graphql/queries";
import {
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "../../config/graphql/mutations";

import useUploadMutation from "../../lib/hooks/useUploadMutation";

type FieldValues = {
  title?: string;
  logo?: IFile;
};

const LogoInput = ({
  value,
  onChange,
}: {
  value?: IFile;
  onChange?: () => void;
}) => {
  const { isLoading, mutateAsync: onUpload } = useUploadMutation();

  return (
    // <ImgCrop zoom quality={0.8} aspect={16 / 9}>
    <Upload.Dragger
      onChange={onChange}
      accept="image/*"
      name="photo"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={(upload) => {
        const { file, filename, onSuccess, onError } = upload;

        return onUpload(file as File)
          .then((data) => {
            // @ts-ignore
            onSuccess(data, upload.file);

            message.success(`${filename} file uploaded successfully.`);
          })
          .catch((error) => {
            // @ts-ignore
            onError(error);
          });
      }}
    >
      {value?.path ? (
        <Image
          preview={false}
          src={value.path}
          // width={200}
          // height={200 / (16 / 9)}
        />
      ) : (
        <div>
          {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      )}
    </Upload.Dragger>
    // </ImgCrop>
  );
};

const DepartmentRoute = () => {
  const { t } = useTranslation(["department"]);

  const history = useHistory();
  const { pathname } = useLocation();

  const [form] = Form.useForm<FieldValues>();

  const { departmentId } = useParams<{ departmentId?: string }>();

  const { loading: isLoading } = useQuery<{ department: IDepartment }>(
    QUERY_DEPARTMENT,
    {
      variables: {
        id: departmentId,
      },
      skip: !departmentId,
      onCompleted: ({ department }) => {
        form.setFieldsValue(department);
      },
      onError: (error) => {
        message.error(error.message);

        history.replace("/departments");
      },
    }
  );

  const [onCreate, { loading: isCreating }] = useMutation<{
    addDepartment: IDepartment;
  }>(CREATE_DEPARTMENT, {
    onCompleted: ({ addDepartment: data }) => {
      message.success("Department created");

      history.replace(`/departments/${data?.id}`);
    },
    refetchQueries: [{ query: QUERY_DEPARTMENTS }],
  });

  const [onUpdate, { loading: isUpdating }] = useMutation(UPDATE_DEPARTMENT, {
    onCompleted: () => {
      message.success("Department updated");
    },
    refetchQueries: [{ query: QUERY_DEPARTMENTS }],
  });

  const onSubmit = (input: FieldValues) => {
    if (departmentId) {
      return onUpdate({
        variables: {
          input: { ...input, id: departmentId, logo: input?.logo?.id },
        },
      }).catch((error) => {
        message.error(error.message);
      });
    }

    return onCreate({
      variables: { input: { ...input, logo: input?.logo?.id } },
    }).catch((error) => {
      message.error(error.message);
    });
  };

  return (
    <>
      <PageHeader
        title="Department"
        breadcrumb={{
          routes: [
            {
              path: "/departments",
              breadcrumbName: "Departments",
            },
            {
              breadcrumbName: "Department",
              path: pathname,
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content">
        <FormProvider
          form={form}
          layout="vertical"
          initialValues={{
            title: "",
            departments: [],
            specialty: "",
          }}
          requiredMark="optional"
          onFinish={onSubmit}
        >
          <Skeleton loading={isLoading}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} xxl={8}>
                <Form.Item
                  label="Department name"
                  name="title"
                  rules={[{ required: true, type: "string" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Logo"
                  name="logo"
                  getValueFromEvent={(e) => {
                    if (Array.isArray(e)) {
                      return e;
                    }

                    /**
                     * ! Important: Allow only oen file to be uploaded
                     */
                    if (e.fileList.length > 1) {
                      e.fileList.shift();
                    }

                    return e.fileList?.[0]?.response;
                  }}
                >
                  <LogoInput />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isCreating || isUpdating}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Skeleton>
        </FormProvider>
      </Layout.Content>
    </>
  );
};

export default DepartmentRoute;
