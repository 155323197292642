import React from "react";

import { Button, Layout, Space, Table } from "antd";

import { useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import type { ColumnsType } from "antd/lib/table";

import PageHeader from "../../components/PageHeader";
import ErrorLayout from "../../components/ErrorLayout";
import { QUERY_VISIONECTS } from "../../config/graphql/queries";

const useTableColumns = (): ColumnsType<IWayfindingVisionect> => {
  return [
    {
      dataIndex: "title",
      title: "Name",
      ellipsis: true,
      render: (value: string, data: IWayfindingVisionect) => (
        <Link to={`/screens/${data.id}`}>{value}</Link>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_value: any, data: IWayfindingVisionect) => (
        <Space size="middle">
          <Link to={`/screens/${data.id}`}>
            <Button type="primary">View</Button>
          </Link>
        </Space>
      ),
    },
  ];
};

const ScreensRoutes = React.memo(() => {
  const { search } = useLocation();

  const columns = useTableColumns();

  const { loading, data, error } =
    useQuery<{ visionects: IWayfindingVisionect[] }>(QUERY_VISIONECTS);

  return (
    <ErrorLayout error={error}>
      <PageHeader
        title="Screens"
        breadcrumb={{
          routes: [
            {
              path: "/screens",
              breadcrumbName: "Screens",
            },
          ],
        }}
        // extra={[
        //   <Link key="create" to="/screens/new">
        //     <Button type="primary">Create</Button>
        //   </Link>,
        // ]}
      />

      <Layout.Content className="site-layout-content">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data?.visionects ?? []}
          loading={loading}
          scroll={{ x: true }}
        />
      </Layout.Content>
    </ErrorLayout>
  );
});

export default ScreensRoutes;
