import { useMemo } from "react";

import map from "lodash/map";

import {
  Layout,
  Form,
  Button,
  Input,
  Skeleton,
  Row,
  Col,
  Card,
  // Tabs,
  Select,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  // Link,
  // Route,
  // Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";

import PageHeader from "../../components/PageHeader";

import { FormProvider } from "../../context/Form";

import { QUERY_DOCTORS, QUERY_VISIONECT } from "../../config/graphql/queries";
import { UPDATE_VISIONECT } from "../../config/graphql/mutations";

interface FieldValues {
  title: string;
  doctors: string[];
}

const DoctorsSelect = (props: {
  id?: string;
  value?: string[];
  onChange?: (value: any) => void;
  limit?: number;
}) => {
  const { value = [], onChange = () => null, limit = 2 } = props;

  const { data, loading } =
    useQuery<{ doctors: IPropertyDoctor[] }>(QUERY_DOCTORS);

  const options = useMemo(
    () => map(data?.doctors, (item) => ({ label: item.title, value: item.id })),
    [data]
  );

  const isMaxValues = value.length === limit;

  return (
    <Select
      loading={loading}
      disabled={loading}
      mode="multiple"
      options={options}
      value={props.value}
      onChange={(value) => {
        onChange(value.slice(0, limit));
      }}
      {...(isMaxValues && { open: false })}
    />
  );
};

const DetailsRoute = () => {
  const [form] = Form.useForm<FieldValues>();

  const params = useParams<{ screenId: string }>();

  const { screenId } = params;

  const { loading: isLoading, data: visionectData } = useQuery<{
    visionect: IWayfindingVisionect;
  }>(QUERY_VISIONECT, {
    variables: {
      id: screenId,
    },
    onCompleted: ({ visionect }) => {
      form.setFieldsValue({
        title: visionect?.title,
        doctors: map(visionect?.items?.[0]?.entity?.properties, "id"),
      });
    },
  });

  const entityId = visionectData?.visionect?.items?.[0]?.entity?.id;
  const previewUrl = visionectData?.visionect?.previewUrl;

  const checksum = useMemo(
    () => JSON.stringify(visionectData),
    [visionectData]
  );

  const [onUpdateVisionect] = useMutation<
    any,
    {
      visionectInput: { id: string; title: string };
      propertiesInput: { id: string; properties: string[] };
    }
  >(UPDATE_VISIONECT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: QUERY_VISIONECT, variables: { id: screenId } }],
  });

  const onSubmit = (input: FieldValues) => {
    if (!entityId) {
      return message.error(
        "Screen is not configure properly. Please contact administrator"
      );
    }

    return onUpdateVisionect({
      variables: {
        visionectInput: { id: screenId, title: input.title },
        propertiesInput: { id: entityId, properties: input.doctors },
      },
    }).then(() => {
      message.success("Screen updated");
    });
  };

  return (
    <FormProvider
      form={form}
      layout="vertical"
      initialValues={{
        title: "",
        properties: [],
      }}
      requiredMark="optional"
      onFinish={onSubmit}
    >
      <Skeleton loading={isLoading}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} xxl={8}>
            <Form.Item
              label="Display name"
              name="title"
              rules={[{ required: true, type: "string" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Doctors"
              name="doctors"
              rules={[{ required: true, type: "array" }]}
            >
              <DoctorsSelect />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} xxl={8} className="border">
            <div className="ratio ratio-3x4">
              <iframe
                key={checksum}
                src={previewUrl}
                title={visionectData?.visionect?.title}
                frameBorder={0}
                allowFullScreen
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                // loading={isUpdating}
                disabled={isLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Skeleton>
    </FormProvider>
  );
};

const Screen = () => {
  const { t } = useTranslation(["screen"]);

  const match = useRouteMatch();

  const { pathname } = useLocation();

  return (
    <>
      <PageHeader
        title="Screen"
        breadcrumb={{
          routes: [
            {
              path: "/screens",
              breadcrumbName: "Screens",
            },
            {
              breadcrumbName: "Screen",
              path: pathname,
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content">
        {/* <Switch>
          <Tabs activeKey={pathname} destroyInactiveTabPane>
            <Tabs.TabPane
              tab={<Link to={match.url}>Details</Link>}
              key={match.url}
            >
              <Route exact path={match.path}>
             
              </Route>
            </Tabs.TabPane>
          </Tabs>
        </Switch> */}
        <DetailsRoute />
      </Layout.Content>
    </>
  );
};

export default Screen;
