import React, { useCallback } from "react";

import { useQuery } from "@apollo/client";
import { Col, Row, Layout, Card, Tooltip, Space } from "antd";
import {
  SettingOutlined,
  ThunderboltOutlined,
  ShakeOutlined,
  WifiOutlined,
  StopOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import PageHeader from "../../components/PageHeader";
import ErrorLayout from "../../components/ErrorLayout";

import { QUERY_VISIONECTS } from "../../config/graphql/queries";

const showRotation = (n: 0 | 1 | 2 | 3 | undefined) => {
  switch (n) {
    case 0:
      return "→ Landscape";
    case 1:
      return "↓ Portrait, 90°";
    case 2:
      return "← Landscape, 180°";
    case 3:
      return "↑ Portrait, 270°";
    default:
      break;
  }
};

const Dashboard = React.memo(() => {
  const { data } =
    useQuery<{ visionects: IWayfindingVisionect[] }>(QUERY_VISIONECTS);

  const renderScreen = useCallback(
    (value: IWayfindingVisionect) => (
      <Col xs={24} sm={12} md={6} xl={4} xxl={4} key={value.id}>
        <Card
          hoverable
          extra={
            <Space>
              <Tooltip
                placement="top"
                title={showRotation(value?.device?.Displays?.[0].Rotation)}
              >
                <ShakeOutlined />
              </Tooltip>
              <Tooltip placement="top" title={value?.device?.State}>
                {value?.device?.State === "offline" ? (
                  <StopOutlined />
                ) : (
                  <WifiOutlined />
                )}
              </Tooltip>
              <Tooltip
                placement="top"
                title={`${value?.device?.Status?.Battery}%`}
              >
                <ThunderboltOutlined />
              </Tooltip>
            </Space>
          }
          cover={
            <div className="ratio ratio-3x4 border-vertical">
              <iframe
                src={value?.previewUrl}
                title={value?.title}
                frameBorder={0}
                allowFullScreen
              />
            </div>
          }
          actions={[
            <Link key="setting" to={`/screens/${value.id}`}>
              <SettingOutlined />
            </Link>,
          ]}
        >
          <Card.Meta title={value?.title} />
        </Card>
      </Col>
    ),
    []
  );

  return (
    <ErrorLayout error={null}>
      <PageHeader
        title="Dashboard"
        breadcrumb={{
          routes: [
            {
              path: "/",
              breadcrumbName: "Dashboard",
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content">
        <Row gutter={16}>{data?.visionects.map?.(renderScreen)}</Row>
      </Layout.Content>
    </ErrorLayout>
  );
});

export default Dashboard;
