import { Layout, Form, Button, Input, message, Skeleton, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import PageHeader from "../../components/PageHeader";

import { FormProvider } from "../../context/Form";
import { useMutation, useQuery } from "@apollo/client";

import {
  QUERY_SPECIALTY,
  QUERY_SPECIALTIES,
} from "../../config/graphql/queries";
import {
  CREATE_SPECIALTY,
  UPDATE_SPECIALTY,
} from "../../config/graphql/mutations";

type FieldValues = {
  title?: string;
};

const SpecialtyRoute = () => {
  const { t } = useTranslation(["specialty"]);

  const history = useHistory();
  const { pathname } = useLocation();

  const [form] = Form.useForm<FieldValues>();

  const { specialtyId } = useParams<{ specialtyId?: string }>();

  const { loading: isLoading } = useQuery<{ specialty: ISpecialty }>(
    QUERY_SPECIALTY,
    {
      variables: {
        id: specialtyId,
      },
      skip: !specialtyId,
      onCompleted: ({ specialty }) => {
        form.setFieldsValue(specialty);
      },
      onError: (error) => {
        message.error(error.message);

        history.replace("/specialties");
      },
    }
  );

  const [onCreate, { loading: isCreating }] = useMutation<{
    addSpecialty: ISpecialty;
  }>(CREATE_SPECIALTY, {
    refetchQueries: [{ query: QUERY_SPECIALTIES }],
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: ({ addSpecialty: data }) => {
      message.success("Specialty created");

      history.replace(`/specialties/${data?.id}`);
    },
  });

  const [onUpdate, { loading: isUpdating }] = useMutation(UPDATE_SPECIALTY, {
    refetchQueries: [{ query: QUERY_SPECIALTIES }],
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: () => {
      message.success("Specialty Updated");
    },
  });

  const onSubmit = (input: FieldValues) => {
    if (specialtyId) {
      return onUpdate({
        variables: {
          input: { ...input, id: specialtyId },
        },
      });
    }

    return onCreate({
      variables: { input },
    });
  };

  return (
    <>
      <PageHeader
        title="Specialties"
        breadcrumb={{
          routes: [
            {
              path: "/specialties",
              breadcrumbName: "Specialties",
            },
            {
              breadcrumbName: "Specialty",
              path: pathname,
            },
          ],
        }}
      />

      <Layout.Content className="site-layout-content">
        <FormProvider
          form={form}
          layout="vertical"
          initialValues={{
            title: "",
          }}
          requiredMark="optional"
          onFinish={onSubmit}
        >
          <Skeleton loading={isLoading}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} xxl={8}>
                <Form.Item
                  label="Specialty name"
                  name="title"
                  rules={[{ required: true, type: "string" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isCreating || isUpdating}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Skeleton>
        </FormProvider>
      </Layout.Content>
    </>
  );
};

export default SpecialtyRoute;
