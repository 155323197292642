import { Switch, Route, useRouteMatch } from "react-router-dom";

import Specialty from "./Specialty";
import Specialties from "./Specialties";

const SpecialtiesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Specialties />
      </Route>
      <Route exact path={`${path}/new`}>
        <Specialty />
      </Route>
      <Route path={`${path}/:specialtyId`}>
        <Specialty />
      </Route>
    </Switch>
  );
};

export default SpecialtiesRouter;
