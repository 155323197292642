import { Switch, Route, useRouteMatch } from "react-router-dom";

import Department from "./Department";
import Departments from "./Departments";

const DepartmentsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Departments />
      </Route>
      <Route exact path={`${path}/new`}>
        <Department />
      </Route>
      <Route path={`${path}/:departmentId`}>
        <Department />
      </Route>
    </Switch>
  );
};

export default DepartmentsRouter;
