import React from "react";

import last from "lodash/last";

import { PageHeader as AntPageHeader } from "antd";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import type { PageHeaderProps } from "antd/lib/page-header";

const PageHeader: React.FC<
  Omit<PageHeaderProps, "title"> & { title?: string }
> = ({ breadcrumb, ...rest }) => {
  const title = rest?.title ?? last(breadcrumb?.routes)?.breadcrumbName;

  return (
    <>
      <Helmet title={title} />
      <AntPageHeader
        {...rest}
        breadcrumb={{
          itemRender: (route, _params, routes) => {
            const last = routes.indexOf(route) === routes.length - 1;

            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
          },
          ...breadcrumb,
        }}
      />
    </>
  );
};

export default PageHeader;
