/* eslint-disable react/no-unused-state */
import React, { Component, ErrorInfo, ReactNode } from "react";

import { Link } from "react-router-dom";
import { Button, Space, Result } from "antd";

interface Props {
  error?: Error | null;
  children: ReactNode;
}

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public static getDerivedStateFromProps({ error }: Props) {
    if (error) {
      return { error };
    }

    return null;
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
  }

  public render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    return (
      <Space
        direction="horizontal"
        align="center"
        className="h-100"
        style={{ justifyContent: "center" }}
      >
        <Result
          status="500"
          title="500"
          subTitle={error.message}
          extra={[
            <Link key="home" to="/">
              <Button type="primary">Return Home</Button>
            </Link>,
            <Button key="report" type="primary">
              <a href="mailto:info@futureforward.nl?subject=Report a problem">
                Report Problem
              </a>
            </Button>,
          ]}
        />
      </Space>
    );
  }
}

export default ErrorLayout;
