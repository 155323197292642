import { Switch, Route, useRouteMatch } from "react-router-dom";

import Doctor from "./Doctor";
import Doctors from "./Doctors";

const DoctorsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Doctors />
      </Route>
      <Route exact path={`${path}/new`}>
        <Doctor />
      </Route>
      <Route path={`${path}/:doctorId`}>
        <Doctor />
      </Route>
    </Switch>
  );
};

export default DoctorsRouter;
