import { ApolloProvider } from "../context/Apollo";
import { QueryClientProvider } from "../context/Query";

import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import { RoutePrivate, RoutePublic } from "../components/Route";

import LayoutPrivate from "../components/Layout/Private";

import * as Page from "../pages";

const PrivateRouter = () => {
  return (
    <LayoutPrivate>
      <Switch>
        <Route exact path="/">
          <Page.Dashboard />
        </Route>
        <Route path="/doctors">
          <Page.Doctors />
        </Route>
        <Route path="/departments">
          <Page.Departments />
        </Route>
        <Route path="/specialties">
          <Page.Specialties />
        </Route>
        <Route path="/screens">
          <Page.Screens />
        </Route>
        <Redirect to="/" />
      </Switch>
    </LayoutPrivate>
  );
};

const Root = () => {
  return (
    <ApolloProvider>
      <QueryClientProvider>
        <BrowserRouter>
          <Switch>
            <RoutePublic exact path="/login">
              <Page.Login />
            </RoutePublic>
            <RoutePrivate path="/">
              <PrivateRouter />
            </RoutePrivate>
            <Redirect to="/login" />
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  );
};

export default Root;
