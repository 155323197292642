import { gql } from "@apollo/client";

export const FRAGMENT_PROPERTY = gql`
  fragment FragmentProperty on Property {
    id
    title
  }
`;

export const FRAGMENT_PROPERTY_DOCTOR = gql`
  fragment FragmentPropertyDoctor on Property {
    id
    ... on Doctor {
      title
      specialty {
        id
        title
      }
      departments {
        id
        title
      }
    }
  }
`;

export const FRAGMENT_VISIONECT_ITEM = gql`
  fragment FragmentVisionectItem on VisionectItem {
    id
    entity {
      id
      properties {
        id
        ... on Doctor {
          title
        }
      }
    }
  }
`;

export const FRAGMENT_VISIONECT = gql`
  fragment FragmentVisionect on Visionect {
    id
    title
    template
    rotation
    device {
      Uuid
      SessionId
      Displays {
        Id
        Rotation
      }
      Options {
        Name
      }
      Status {
        Battery
      }
      State
    }
    previewUrl
    items {
      ...FragmentVisionectItem
    }
  }
  ${FRAGMENT_VISIONECT_ITEM}
`;
