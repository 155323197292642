import React, { useMemo } from "react";

import clsx from "clsx";
import useToggle from "react-use/lib/useToggle";

import { useApolloClient, useQuery } from "@apollo/client";
import { Layout, Menu, Dropdown, Avatar, Space } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  DesktopOutlined,
  TeamOutlined,
  ExperimentOutlined,
} from "@ant-design/icons";
import { CURRENT_USER_QUERY } from "../../../lib/graphql/query";

const { Header, Sider } = Layout;

const UserMenu = () => {
  const client = useApolloClient();

  const { data } = useQuery<{ me: IUser }>(CURRENT_USER_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const onLogout = () => {
    client.clearStore();

    window.localStorage.removeItem("Authorization");
  };

  const menu = (
    <Menu style={{ minWidth: 160 }}>
      <Menu.Item key="logout" onClick={onLogout} danger>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} className="cursor-pointer">
      <Avatar shape="circle" size={32}>
        {[data?.me?.firstName?.[0], data?.me?.lastName?.[0]]
          .filter(Boolean)
          .join("")}
      </Avatar>
    </Dropdown>
  );
};

const LayoutPrivate: React.FC = ({ children }) => {
  const [collapsed, toggle] = useToggle(false);

  const { pathname } = useLocation();

  const defaultSelectedKeys = useMemo(
    () => [`/${pathname.split("/")[1] || ""}`],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const selectedKeys = useMemo(
    () => [`/${pathname.split("/")[1] || ""}`],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return (
    <Layout className="site-layout-main">
      <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          selectedKeys={selectedKeys}
        >
          <Menu.Item key="/" icon={<DashboardOutlined />}>
            <NavLink exact to="/">
              Dashboard
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/doctors" icon={<UserOutlined />}>
            <NavLink to="/doctors">Doctors</NavLink>
          </Menu.Item>
          <Menu.Item key="/departments" icon={<TeamOutlined />}>
            <NavLink to="/departments">Departments</NavLink>
          </Menu.Item>
          <Menu.Item key="/specialties" icon={<ExperimentOutlined />}>
            <NavLink to="/specialties">Specialties</NavLink>
          </Menu.Item>
          <Menu.Item key="/screens" icon={<DesktopOutlined />}>
            <NavLink to="/screens">Screens</NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        className={clsx("site-layout", {
          open: false,
        })}
      >
        <Header className="site-layout-background site-layout-header">
          <Space direction="horizontal" size="middle" align="center">
            <UserMenu />
          </Space>
        </Header>
        {children}
      </Layout>
    </Layout>
  );
};

export default LayoutPrivate;
